import styled from "styled-components/macro";
import { device } from "../components/Helpers";

export const EdetailHomeStyle = styled.main`
	background: transparent;
	height: 42.5rem;
	margin-top: 8rem !important;
	position: relative;
	&#main {
		@media ${device.mobile} {
			height: 63vw;
			margin-top: 32rem !important;
			overflow: visible;
			padding-bottom: 0 !important;
			padding-top: 0 !important;
			margin: 32.5rem auto 0 !important;
		}
	}

	@media ${device.tablet} {
		margin-top: 7rem;
	}

	@media ${device.desktop} {
		margin-top: 6rem !important;
	}

	@media only screen and (min-width: 1280px) {
		margin-top: 8rem !important;
	}

	@media screen and (min-width: 992px) {
		margin-top: 4.85rem;
	}

	@media screen and (min-width: 992px) and (max-width: 1279px) {
		margin-top: 6rem;
	}

	& > .md-container {
		margin-top: 11rem;
		position: relative;
		z-index: 1;
		@media ${device.mobile} {
			// margin-top: 90%;
		}
	}

	&.wrapper {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-radius: 5px !important;

		@media ${device.mobile} {
			border-radius: 10px;
		}
	}

	p {
		@media ${device.mobile} {
			font-size: 1.333rem;
		}
	}

	p {
		@media ${device.mobile} {
			font-size: 1.333rem;
		}
	}

	#hero-container {
		/* margin-top: 5rem; */
		padding-bottom: 37.5%;
		position: relative;
		background-image: linear-gradient(to bottom, #ece5f4 1%, #fff 66%);

		@media ${device.mobile} {
			height: 64vw;
			left: 0;
			margin-left: 0;
			margin-top: 15%;
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom-right-radius: 0.25rem;
			border-bottom-left-radius: 0.25rem;
		}

		@media screen and (min-width: 430px) and (max-width: 767px) {
			margin-top: 7.5%;
		}

		@media ${device.tablet} {
			height: 57vw;
		}

		@media ${device.desktop} {
			height: 57vw;
		}

		& > h2 {
			bottom: 0;
			height: 4rem;
			left: 0;
			margin: 0 auto 0rem;
			padding: 0 1.15rem;
			position: absolute;
			text-align: left;
			width: 100%;

			@media ${device.mobile} {
				bottom: unset;
				margin-top: 43%;
				top: 0;
			}

			@media ${device.tablet} {
				margin-bottom: 18%;
			}

			@media ${device.desktop} {
				margin-bottom: 21%;
			}
		}

		& > img {
			left: 0;
			margin-left: 0;
			margin-top: 0;
			max-width: 100%;
			position: absolute;
			top: 0;
			width: 97.5%;
			padding-right: 0;
			padding-top: 1rem;

			@media ${device.mobile} {
				left: 0m;
				margin-left: 0;
				max-width: none;
				padding-right: 1rem;
				padding-top: 3rem;
				top: 0;
				width: 100%;
			}
		}
	}

	h2 {
		@media ${device.mobile} {
			font-size: 1.583rem;
		}
	}

	#home-cta-container {
		margin: 6.625rem auto 5rem;

		@media ${device.mobile} {
			margin: -3rem auto 5rem;
		}

		#home-ctas {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding: 0 1.25rem;

			@media ${device.mobile} {
				flex-direction: column;
				padding: 0;
				margin: 5rem auto 3.5rem auto;
			}

			& > div {
				@media ${device.mobile} {
					margin: 3.5rem auto;
				}

				&:last-of-type {
					@media ${device.mobile} {
						margin-bottom: 0;
					}
				}

				p {
					@media ${device.mobile} {
						font-size: 1.333rem;
						line-height: 1.75rem;
					}
				}

				&.checkbox::before {
					@media ${device.mobile} {
						height: 5.15rem;
					}
				}
			}
		}
	}

	a.btn-1,
	a.btn-2,
	a.btn-3 {
		@media ${device.mobile} {
			font-size: 1.166rem;
		}
	}

	.roundedges {
		bottom: -80px !important;
	}

	.hero-wrapper {
		/* margin-top: ${(props) => (props.isHCP ? "12.25rem" : "12rem")}; */
		margin-top: 0;
		padding: 0;
		border-top-left-radius: 0.25rem;
		border-top-right-radius: 0.25rem;
		background-color: ${(props) => props.theme.colors.brand_purple_00};
		position: absolute;
		left: 0;
		top: 0;
		width: 100% !important;

		@media screen and (min-width: 1200px) and (max-width: 1279px) {
			margin-top: 6rem;
		}

		@media ${device.mobile} {
			left: 0;
			margin-top: 0;
			position: absolute !important;
			top: 0;
			width: 100%;
			height: inherit;
		}

		& > h1 {
			color: ${(props) => props.theme.colors.white};
			line-height: 6rem;

			@media screen and (min-width: 375px) and (max-width: 991px) {
				line-height: 2.5rem !important;
				margin: 1rem auto;
			}
		}

		& > hr {
			background: ${(props) => props.theme.colors.white};
			border-bottom: 1px solid ${(props) => props.theme.colors.white};
			margin-top: 1rem;
			position: relative;
			top: 0.75rem;

			@media ${device.mobile} {
				margin-top: -5.25rem;
			}
		}

		/* @media ${device.desktop_xlgmx} {
      margin-top: 6.5rem;
    } */

		@media ${device.mobile} {
			position: relative;
			// margin-bottom: 22rem;
			margin-top: 0;
		}
	}

	.mobile-hide {
		@media ${device.mobile} {
			display: none;
		}
	}

	.mobile-show {
		display: none;

		@media ${device.mobile} {
			display: block;
		}
	}
`;
