import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { isWindow } from "../components/Helpers";
import { EdetailHomeStyle } from "../styles/EdetailHomeStyle";
import { AudioTranscript } from "../components/AudioTranscript";
import {
  Container,
  References,
  Definitions,
  ConvertedMarkdown,
  TableOfContents
} from "../components";

const EdetailHome = ({ data }) => {
  const [isHCP, setHCP] = useState(false);
  const [trackWProps, setTrackWProps] = useState('default');
  const [transcriptState, setTranscriptState] = useState(false);

  useEffect(() => {
    if (isWindow) {
      let isHCP = sessionStorage.getItem("hcp");
      if (isHCP !== "true") {
        setHCP(false);
      } else {
        setHCP(true);
      }
    }
  }, [isHCP, setHCP]);

  const dataQuery = data.allMarkdownRemark.nodes[0],
    componentID = data.allMarkdownRemark.nodes[0].frontmatter.components_id,
    markdownHTML = data.allMarkdownRemark.nodes[0].html,
    audioTracksWithProps = data.allMarkdownRemark.nodes[0].frontmatter.audioTracksWithProps[0],
    isiSnippet = data.allMarkdownRemark.nodes[0].frontmatter.isiSnippet,
    markdownID = `home-content`;

  return (
    <>
      <Container
        markdownID={markdownID}
        componentID={componentID}
        query={dataQuery}
        noSubNav={false}
        isiSnippet={isiSnippet}
        trackWProps={audioTracksWithProps[trackWProps]}
        setTrackWProps={setTrackWProps}
        transcriptState={transcriptState}
        setTranscriptState={setTranscriptState}
      >
        <EdetailHomeStyle id={`main`} className={`wrapper`} isHCP={isHCP}>
          <div className={`hero-wrapper`}>
            <hr />
            <h1>Your tour of ARIKAYCE begins here. Pick a topic below to get started.</h1>
            <div id={`hero-container`}>
              <img src={'/images/hcp-breakthe-pattern-campaign-rgb-wheadline.png'} alt={`Arikayce campaign`} />
              <h2>When standard therapy fails to achieve culture conversion for MAC, you can change what comes next.<sup>1</sup></h2>
            </div>
          </div>
        </EdetailHomeStyle>
        <TableOfContents />
        <div id={`toc-container`} className={`wrapper`}>
          <ConvertedMarkdown markdownID={markdownID} mdhtml={markdownHTML} />
        </div>
        <>
          <div className={`wrapper homepage`}>
            <Definitions className={"definitions-home"} definitions={dataQuery.frontmatter.definitions} />
            <References
              className={"references-home"}
              markdownID={markdownID}
              references={dataQuery.frontmatter.references}
            />
          </div>
        </>
        <AudioTranscript id={`audio-transcript-main`} className={`purpbg home-transcript`} setTranscriptState={setTranscriptState} transcriptState={transcriptState} transcriptCopy={audioTracksWithProps[trackWProps].transcript} markdownID={markdownID} />
      </Container>
    </>
  );
};

export const query = graphql`
  {
    allMarkdownRemark(
      filter: { frontmatter: { markdown_id: { eq: "ed-home-content" } } }
    ) {
      nodes {
        frontmatter {
          slug
          description
          keywords
          markdown_id
          title
          components_id
          definitions
          footnotes
          references
          isiSnippet
		  audioTracksWithProps {
			  default {
				  trackID
				  isiContent
				  src
				  title
				  transcript
			  }
		  }
        }
        id
        html
      }
    }
  }
`;

export default EdetailHome;
